(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/shares/assets/javascripts/shares-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/shares/assets/javascripts/shares-actions.js');
"use strict";


const {
  services
} = svs.components.marketplaceData.shares;
const {
  dialogWrapper
} = svs.components.marketplaceData;
const FETCH_SHARES_INIT = 'SHARES/FETCH_SHARES_INIT';
const FETCH_SHARES_FAILED = 'SHARES/FETCH_SHARES_FAILED';
const FETCH_SHARES_SUCCESS = 'SHARES/FETCH_SHARES_SUCCESS';
const fetchSharesAction = function (offset) {
  let callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => {};
  return dispatch => {
    dispatch({
      type: FETCH_SHARES_INIT,
      offset
    });
    services.fetchShares(offset, (error, data) => {
      if (error) {
        const errMsg = 'Något gick fel.';
        dispatch(dialogWrapper.actions.showDialog({
          title: errMsg,
          type: 'error'
        }));
        dispatch({
          type: FETCH_SHARES_FAILED,
          error
        });
        callback(error);
      } else {
        dispatch({
          type: FETCH_SHARES_SUCCESS,
          data
        });
        callback(undefined);
      }
    });
  };
};
setGlobal('svs.components.marketplaceData.shares.actions', {
  FETCH_SHARES_INIT,
  FETCH_SHARES_FAILED,
  FETCH_SHARES_SUCCESS,
  fetchSharesAction
});

 })(window);