(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/router/assets/javascripts/marketplace-router-v2.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/router/assets/javascripts/marketplace-router-v2.js');

'use strict';

const {
  useEffect
} = React;
const {
  matchPath,
  Route,
  Link
} = ReactRouterDOM;
const {
  baseUrl
} = svs.core.data;
const {
  clx
} = svs.components.lbUtils;
const {
  ErrorBoundary
} = svs.components.errorBoundary;
const {
  MarketplaceOnboarding,
  MarketplaceTeam,
  MarketplaceRetail,
  MarketplaceJoinTeam,
  MarketplaceShares
} = svs.marketplace.components.router;
const {
  ItemsMenu,
  LinkItem
} = svs.ui.ReactItemsMenu;
const {
  lazy
} = svs.components.lbUtils.lazy;
const {
  TabsLoader
} = svs.marketplace.components.router;
const CreateTeamWizard = lazy('/cl/marketplace/react:create-team', () => svs.components.marketplace.createTeam.CreateTeamWizard, {
  fallback: TabsLoader
});
const MarketplaceRouterV2 = () => {
  useEffect(() => {
    svs.components.header.triggerOnHeaderVisibilityChange(true, ['xs', 'sm']);
    return () => {
      svs.components.header.triggerOnHeaderVisibilityChange(false, ['xs', 'sm']);
    };
  }, []);
  return React.createElement("div", {
    className: "marketplace-router"
  }, React.createElement(svs.app.ReactRouter.PlayerRouter, {
    basename: baseUrl,
    className: "qa-marketplace-player-router",
    render: _ref => {
      let {
        location
      } = _ref;
      const tabs = [{
        exact: false,
        component: MarketplaceTeam,
        path: '/lag',
        label: 'Spela i lag',
        isActive: () => location.pathname === '/lag'
      }, {
        exact: false,
        component: MarketplaceRetail,
        path: '/ombudslag',
        label: 'Butiksandelar',
        isActive: () => {
          const matchedTabs = tabs.filter(() => {
            const m1 = matchPath(location.pathname, {
              path: '/ombudslag'
            });
            const m2 = matchPath(location.pathname, {
              path: '/hitta-andelsspel'
            });
            return m1 || m2;
          });
          return matchedTabs.length > 0;
        }
      }];
      if (svs.core.detect.feature('ft-marketplace-bought-shares')) {
        tabs.push({
          exact: false,
          component: MarketplaceShares,
          path: '/mina-andelar',
          label: 'Köpta andelar',
          isActive: () => location.pathname === '/mina-andelar'
        });
      }
      const routes = [{
        exact: false,
        component: MarketplaceOnboarding,
        path: '/',
        label: 'Spela tillsammans'
      }, {
        component: MarketplaceJoinTeam,
        path: '/bli-medlem/:invitationToken?',
        exact: true
      }, {
        component: CreateTeamWizard,
        path: '/skapa-lag'
      }, ...tabs, {
        exact: false,
        component: MarketplaceRetail,
        path: '/hitta-andelsspel'
      }];
      const shouldShowNavbar =
      tabs.filter(t => matchPath(location.pathname, {
        path: t.path,
        exact: true
      })).length === 1 || Boolean(matchPath(location.pathname, {
        path: '/hitta-andelsspel'
      }));
      return React.createElement(React.Fragment, null, tabs.length > 1 && shouldShowNavbar && React.createElement(ItemsMenu, {
        branding: "sport",
        centered: true,
        className: "marketplace-routed-tabs qa-marketplace-tabs-menu",
        hiddenScrollbar: true,
        useScrollFaders: true
      }, tabs.map(_ref2 => {
        let {
          label,
          path,
          isActive
        } = _ref2;
        return React.createElement(LinkItem, {
          Component: Link,
          id: "qa-".concat(label.toLowerCase().replace(/å|ä/, 'a').replace('ö', 'o')
          .replaceAll(/\s/g, '-')),
          key: path,
          label: label,
          selected: isActive(),
          to: path
        });
      })), React.createElement("div", {
        className: clx('marketplace-routed-content tabs-marketplace-fixed-content', {
          'no-navbar': !shouldShowNavbar
        })
      }, React.createElement(ErrorBoundary, null, React.createElement(ReactRouterDOM.Switch, null, routes.sort((a, b) => b.path.length - a.path.length).map(_ref3 => {
        let {
          exact,
          path,
          component
        } = _ref3;
        return React.createElement(Route, {
          component: component,
          exact: exact,
          key: path,
          path: path
        });
      })))));
    }
  }));
};
setGlobal('svs.marketplace.components.router.MarketplaceRouterV2', MarketplaceRouterV2);

 })(window);