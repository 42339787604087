(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/router/assets/javascripts/marketplace-shares.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/router/assets/javascripts/marketplace-shares.js');

'use strict';

const {
  Route,
  useHistory
} = ReactRouterDOM;
const {
  lazy
} = svs.components.lbUtils.lazy;
const {
  TabsLoader
} = svs.marketplace.components.router;
const {
  urlMapping
} = svs.core;
const MyShares = lazy('react:my-shares', () => svs.marketplace.components.myShares.MyShares, {
  fallback: TabsLoader
});
const MarketplaceShares = () => {
  const history = useHistory();
  const loggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  if (!loggedIn) {
    const url = "".concat(urlMapping.get('loginHome'), "?returnUrl=").concat(urlMapping.get('marketplace')).concat(history.location.pathname);
    window.location = url;
    return null;
  }
  return React.createElement("div", {
    className: "marketplace-shares"
  }, React.createElement(Route, {
    component: MyShares,
    exact: true,
    path: "/mina-andelar"
  }));
};
setGlobal('svs.marketplace.components.router.MarketplaceShares', MarketplaceShares);

 })(window);