(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/cover-image/assets/javascripts/cover-image.js') >= 0) return;  svs.modules.push('/components/marketplace/cover-image/assets/javascripts/cover-image.js');

'use strict';

const {
  ReactIcon
} = svs.ui;
const {
  RetailCoverImage
} = svs.components.marketplaceRetailAdmin.retailCoverImage.RetailCoverImage;
const CoverImage = _ref => {
  let {
    image,
    onNavigateBack
  } = _ref;
  return React.createElement("div", {
    className: "retail-view-header-image-container",
    "data-testid": "header-image-container"
  }, onNavigateBack ? React.createElement("div", {
    className: "cover-image-back",
    "data-testid": "cover-image-back"
  }, React.createElement("div", {
    className: "retail-view-header-back-container",
    "data-testid": "header-back-container"
  }, React.createElement("button", {
    className: "qa-back-button icon-button",
    "data-testid": "cover-image-back-button",
    onClick: onNavigateBack,
    type: "button"
  }, React.createElement(ReactIcon, {
    color: "white",
    icon: "close",
    size: "300"
  })))) : React.createElement("div", {
    className: "retail-view-header-badge-container",
    "data-testid": "header-badge-container"
  }, React.createElement("div", {
    className: "retail-view-header-badge",
    "data-testid": "header-badge"
  }, React.createElement(ReactIcon, {
    color: "fc-white",
    icon: "vinnare",
    size: "200"
  }))), React.createElement("div", {
    className: "jumbo-image",
    "data-testid": "jumbo-image"
  }, React.createElement(RetailCoverImage, {
    image: image
  })));
};
setGlobal('svs.components.marketplace.coverImage.CoverImage', CoverImage);

 })(window);