(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/error-code-handler/assets/javascripts/error-code-handler.js') >= 0) return;  svs.modules.push('/components/marketplace-data/error-code-handler/assets/javascripts/error-code-handler.js');

'use strict';

const {
  constants: {
    warnings
  },
  helpers: {
    getCustomerDrawAmountLimit
  }
} = svs.components.marketplaceData.errorCodeHandler;
const {
  format
} = svs.utils;
const exceedsMaxAmount = Object.freeze({
  readMessage: 'Laget överskrider max-gränsen på denna omgång.',
  specificProductIdMessage: productId => "Laget f\xE5r inte spela f\xF6r mer \xE4n ".concat(svs.utils.format.Currency(getCustomerDrawAmountLimit(productId)), " kr p\xE5 samma spelomg\xE5ng."),
  warning: warnings.WAGER_MAX_GROUP_AMOUNT_EXCEEDED
});
const codes = {
  2: {
    readMessage: 'Du kan inte betala ett spel för under 10 kronor.',
    specificGameMessage: () => 'Något gick fel, försök igen senare.',
    specificFormMessage: () => 'Något gick fel, kontrollera att alla fält är korrekt ifyllda.',
    specificTeamMessage: () => 'Något gick fel, det gick inte att följa ombudet.',
    warning: warnings.AMOUNT_BELOW_LIMIT
  },
  3: {
    readMessage: 'Laget har avslutats eller är påväg att avslutas',
    specificJoinTeamMessage: () => 'Du kan inte gå med i det här laget eftersom att det har avslutats',
    warning: warnings.GROUP_NOT_ACTIVE
  },
  5: {
    readMessage: 'Kunde inte hitta inbjudan. Kontrollera länken eller be lagkaptenen om en ny.',
    warning: warnings.TOKEN_MISSING
  },
  7: {
    readMessage: 'Inbjudningslänken är inte längre giltig. Du kan be lagkaptenen skapa en ny.',
    warning: warnings.INVITATION_EXPIRED
  },
  9: {
    readMessage: 'Du kan inte gå med i laget eftersom att det är fullt.',
    specificTeamNameMessage: name => "Du kan inte g\xE5 med i ".concat(name, " eftersom laget \xE4r fullt."),
    warning: warnings.TEAM_FULL
  },
  11: {
    readMessage: 'Du är redan medlem i laget',
    specificTeamNameMessage: name => "Du \xE4r redan medlem i ".concat(name, "."),
    warning: warnings.ALREADY_MEMBER
  },
  12: {
    readMessage: 'Något gick fel i medlemshanteringen.',
    warning: warnings.MEMBER_NOT_FOUND
  },
  13: {
    readMessage: 'Lagnamnet är upptaget.',
    specificTeamNameMessage: name => "Lagnamet \"".concat(name, "\" \xE4r upptaget."),
    warning: warnings.GROUP_NAME_ALREADY_EXISTS
  },
  15: {
    checkPlayerExclusions: true,
    warning: warnings.CUSTOMER_NOT_ACTIVE
  },
  18: {
    readMessage: 'Du har nått max antal lag.',
    specificFollowLimitMessage: (name, apiMessage) => {
      const limitText = "redan \xE4r med i ".concat(getLimitFromApiMessage(apiMessage), " lag");
      return "Du kan inte g\xE5 med i ".concat(name, ", eftersom att du ").concat(limitText, ".");
    },
    specificFractionPaymentMessage: apiMessage => "Det gick inte att k\xF6pa en andel, eftersom du f\xF6ljer max antal lag (".concat(getLimitFromApiMessage(apiMessage), ")."),
    warning: warnings.MAX_GROUPS_REACHED
  },
  19: {
    readMessage: 'Det gick inte att köpa en andel just nu, försök igen senare.',
    warning: warnings.MEMBER_ACTIVE
  },
  20: {
    readMessage: 'Det går inte att utföra denna åtgärd eftersom det finns aktiva spel.',
    specificGameMessage: () => 'Det gick inte att köpa en andel just nu på grund av ett tekniskt fel, försök igen.',
    specificTeamMessage: () => 'Det gick inte att avsluta laget eftersom det finns aktiva spel.',
    warning: warnings.GROUP_ACTIVE
  },
  21: {
    readMessage: 'Det gick inte att köpa någon andel på grund av ett tekniskt fel.',
    warning: warnings.CREATOR_BUYIN_FAILED
  },
  22: {
    readMessage: 'Du överskrider max-gränsen på denna omgång.',
    specificProductIdMessage: productId => "Du \xF6verskrider maxgr\xE4nsen ".concat(format.Currency(getCustomerDrawAmountLimit(productId)), " kr p\xE5 denna omg\xE5ng."),
    warning: warnings.ITS_MAX_AMOUNT_EXCEEDED
  },
  23: {
    readMessage: 'Det går inte att köpa fler andelar i spelet.',
    warning: warnings.NO_SHARES_AVAILABLE
  },
  24: {
    readMessage: 'Du har för lite pengar på ditt spelkonto.',
    warning: warnings.OPS_INSUFFICIENT_FUNDS
  },
  26: {
    readMessage: 'Vår server svarar inte på grund av ett tekniskt fel.',
    warning: warnings.OPS_ACCOUNT_NOT_REACHABLE
  },
  28: {
    readMessage: 'Din kontostatus tillåter inte köp. Kontakta gärna kundservice om du vill veta mer.',
    warning: warnings.CUSTOMER_INACTIVE_ACTIVE_IN_GROUP
  },
  35: {
    readMessage: 'Du behöver vara speladmin eller lagkapten för att utföra denna åtgärd.',
    warning: warnings.USER_NOT_OWNER_OR_ADMIN
  },
  36: {
    readMessage: 'Vi kunde inte hitta spelet.',
    warning: warnings.CONTAINER_NOT_FOUND
  },
  37: {
    readMessage: 'Vi kunde inte hitta spelet.',
    warning: warnings.ACTIVATED_DRAW_NOT_FOUND
  },
  39: {
    readMessage: 'För få andelar sålda på spelet.',
    warning: warnings.TOO_FEW_SHARES_SOLD
  },
  41: {
    readMessage: 'Spelet verkar redan vara inlämnat.',
    warning: warnings.ACTIVATED_DRAW_CLOSED
  },
  43: {
    readMessage: 'Vår server svarar inte på grund av ett tekniskt fel.',
    warning: warnings.NO_OPS_REPLY
  },
  44: {
    readMessage: 'Vår server svarar inte på grund av ett tekniskt fel.',
    warning: warnings.NO_ITS_REPLY
  },
  47: {
    readMessage: 'Du har redan maximalt antal andelar.',
    warning: warnings.MAX_SHARES_PER_MEMBER_REACHED
  },
  49: {
    readMessage: 'Något gick fel - kolla så att du har angett rätt information.',
    specificGameMessage: () => 'Andelsköpstiden du har valt har passerat.',
    warning: warnings.BUYIN_CLOSE_BEFORE_CURRENT
  },
  50: {
    readMessage: 'Servern är upptagen, försök igen senare.',
    warning: warnings.MINERVA_BUSY
  },
  55: exceedsMaxAmount,
  56: {
    readMessage: 'Det finns redan ett spel skapat på den här omgången.',
    warning: warnings.BAD_DRAWNUM
  },
  58: {
    readMessage: 'Produkten är tillfälligt stängd, försök igen senare.',
    warning: warnings.REGBET_DISABLED
  },
  72: exceedsMaxAmount,
  90: {
    readMessage: 'Du har nått din tidsgräns.',
    warning: warnings.PLAYER_REACHED_TIMELIMIT
  },
  401: {
    readMessage: 'Du saknar behörighet för denna åtgärd.'
  },
  404: {
    readMessage: 'Vi kunde inte hitta det du söker.',
    warning: warnings.RESOURCE_NOT_FOUND
  }
};
const getLimitFromApiMessage = apiMessage => {
  const unescapedMsg = unescape(apiMessage);
  const value = unescapedMsg.substring(unescapedMsg.indexOf('(') + 1, unescapedMsg.indexOf(')'));
  return parseInt(value, 10) || 'max antal';
};
const getReadMessage = function () {
  let error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return error.readMessage || options.defaultMessage || 'Något gick fel, var god försök igen senare.';
};
const getMessageByOptions = function () {
  let error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (options.overrideErrorMessage && options.overrideErrorMessage[error.warning]) {
    return options.overrideErrorMessage[error.warning];
  }
  if (options.productId && error.specificProductIdMessage) {
    return error.specificProductIdMessage(options.productId);
  }
  if (options.specificGameMessage && error.specificGameMessage) {
    return error.specificGameMessage();
  }
  if (options.specificFormMessage && error.specificFormMessage) {
    return error.specificFormMessage();
  }
  if (options.specificTeamMessage && error.specificTeamMessage) {
    return error.specificTeamMessage();
  }
  if (options.specificFollowLimitMessage && error.specificFollowLimitMessage) {
    return error.specificFollowLimitMessage(options.name, options.apiMessage);
  }
  if (options.specificFractionPaymentMessage && error.specificFractionPaymentMessage) {
    return error.specificFractionPaymentMessage(options.apiMessage);
  }
  if (options.name && error.specificTeamNameMessage) {
    return error.specificTeamNameMessage(options.name);
  }
  if (options.joinTeamMessage && error.specificJoinTeamMessage) {
    return error.specificJoinTeamMessage();
  }
  return '';
};
const getErrorMessage = (errorCode, callback, options) => {
  const error = codes[errorCode] || {};
  let errorMessage = getReadMessage(error, options);
  if (getWarning(errorCode) === warnings.NOT_DEFINED) {
    callback({
      message: errorMessage
    });
  } else {
    if (options) {
      errorMessage = getMessageByOptions(error, options) || errorMessage;
    }
    callback({
      message: errorMessage,
      warning: error.warning,
      isHTML: false
    });
  }
};
const getWarning = errorCode => codes[errorCode] ? codes[errorCode].warning : warnings.NOT_DEFINED;
setGlobal('svs.components.marketplaceData.errorCodeHandler.getErrorMessage', getErrorMessage);
setGlobal('svs.components.marketplaceData.errorCodeHandler.getWarning', getWarning);

 })(window);