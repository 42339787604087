(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/retail-cover-image/assets/javascripts/retail-cover-image.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/retail-cover-image/assets/javascripts/retail-cover-image.js');
"use strict";


const {
  getCoverImageUrl,
  getDefaultCoverImageUrl
} = svs.components.marketplaceRetailAdmin.helpers.getCoverImageUrl;
const RetailCoverImage = _ref => {
  let {
    image,
    classname = ''
  } = _ref;
  const imageUrl = getCoverImageUrl(image);
  let hasErrorOccurred = false;
  return React.createElement("img", {
    alt: "CoverImage",
    className: classname,
    onError: e => {
      if (!hasErrorOccurred) {
        e.target.onerror = null;
        e.target.src = getDefaultCoverImageUrl();
        hasErrorOccurred = true;
      }
    },
    src: imageUrl,
    style: {
      display: 'block'
    }
  });
};
setGlobal('svs.components.marketplaceRetailAdmin.retailCoverImage.RetailCoverImage', {
  RetailCoverImage
});

 })(window);