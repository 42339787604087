(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/game-templates/assets/javascripts/game-templates-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/game-templates/assets/javascripts/game-templates-actions.js');
"use strict";


const {
  dialogWrapper
} = svs.components.marketplaceData;
const {
  services
} = svs.components.marketplaceRetailAdminData.gameTemplates;
const getActionName = name => "GAME_TEMPLATES/".concat(name);
const FETCH_GAME_TEMPLATES_FAILURE = getActionName('FETCH_GAME_TEMPLATES_FAILURE');
const FETCH_GAME_TEMPLATES_INIT = getActionName('FETCH_GAME_TEMPLATES_INIT');
const FETCH_GAME_TEMPLATES_SUCCESS = getActionName('FETCH_GAME_TEMPLATES_SUCCESS');
const fetchGameTemplates = groupId => dispatch => {
  dispatch({
    type: FETCH_GAME_TEMPLATES_INIT,
    groupId
  });
  services.fetchTemplates(function (error) {
    let {
      templates,
      users
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (error) {
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Det gick inte att hämta spelmallarna, försök igen senare',
        type: 'error'
      }));
      return dispatch({
        type: FETCH_GAME_TEMPLATES_FAILURE,
        error,
        groupId
      });
    }
    dispatch({
      type: FETCH_GAME_TEMPLATES_SUCCESS,
      groupId,
      templates,
      users,
      lastFetched: Date.now()
    });
  });
};
const UPDATE_GAME_TEMPLATE_INIT = getActionName('UPDATE_GAME_TEMPLATE_INIT');
const UPDATE_GAME_TEMPLATE_FAILURE = getActionName('UPDATE_GAME_TEMPLATE_FAILURE');
const UPDATE_GAME_TEMPLATE_SUCCESS = getActionName('UPDATE_GAME_TEMPLATE_SUCCESS');
const updateGameTemplate = (groupId, gameTemplateId, data) => dispatch => {
  dispatch({
    type: UPDATE_GAME_TEMPLATE_INIT,
    gameTemplateId
  });
  services.updateTemplate(gameTemplateId, data, (error, gameTemplate) => {
    if (error) {
      dispatch({
        type: UPDATE_GAME_TEMPLATE_FAILURE,
        error,
        gameTemplateId,
        groupId
      });
      dispatch(dialogWrapper.actions.showDialog({
        title: error.message,
        type: 'error'
      }));
      return;
    }
    dispatch({
      type: UPDATE_GAME_TEMPLATE_SUCCESS,
      gameTemplateId,
      gameTemplate,
      groupId
    });
  });
};
const FETCH_SINGLE_TEMPLATE_INIT = getActionName('FETCH_SINGLE_TEMPLATE_INIT');
const FETCH_SINGLE_TEMPLATE_SUCCESS = getActionName('FETCH_SINGLE_TEMPLATE_SUCCESS');
const FETCH_SINGLE_TEMPLATE_FAILURE = getActionName('FETCH_SINGLE_TEMPLATE_FAILURE');
const fetchSingleTemplate = (groupId, gameTemplateId, cb) => dispatch => {
  dispatch({
    type: FETCH_SINGLE_TEMPLATE_INIT,
    groupId,
    gameTemplateId
  });
  services.fetchSingleTemplate(groupId, gameTemplateId, (error, result) => {
    if (error) {
      dispatch({
        type: FETCH_SINGLE_TEMPLATE_FAILURE,
        gameTemplateId,
        groupId,
        error
      });
      cb(error);
    } else {
      dispatch({
        type: FETCH_SINGLE_TEMPLATE_SUCCESS,
        template: result.template,
        user: result.user,
        groupId,
        gameTemplateId
      });
      cb(undefined, result.template);
    }
  });
};
const UPDATE_SORT_ORDER_INIT = getActionName('UPDATE_SORT_ORDER_INIT');
const UPDATE_SORT_ORDER_SUCCESS = getActionName('UPDATE_SORT_ORDER_SUCCESS');
const UPDATE_SORT_ORDER_FAILURE = getActionName('UPDATE_SORT_ORDER_FAILURE');
const updateSortOrder = (groupId, containerId, type) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_SORT_ORDER_INIT,
    groupId,
    containerId
  });
  const toDispatch = [];
  const input = [];
  const pinnedContainers = getState().GameTemplates.Templates[groupId].data.filter(template => template.sortOrder).sort((t1, t2) => t2.sortOrder - t1.sortOrder);
  switch (type) {
    case 'PIN':
      {
        input.push({
          sortOrder: pinnedContainers.length ? pinnedContainers[0].sortOrder + 1 : 1,
          containerId
        });
        break;
      }
    case 'UNPIN':
      {
        input.push({
          sortOrder: 0,
          containerId
        });
        break;
      }
    case 'MOVE_UP':
      {
        const currentIndex = pinnedContainers.findIndex(c => c.id === containerId);
        const container = pinnedContainers[currentIndex];
        const otherContainer = pinnedContainers[currentIndex - 1];
        input.push({
          sortOrder: otherContainer.sortOrder,
          containerId
        }, {
          sortOrder: container.sortOrder,
          containerId: otherContainer.id
        });
        break;
      }
    case 'MOVE_DOWN':
      {
        const currentIndex = pinnedContainers.findIndex(c => c.id === containerId);
        const container = pinnedContainers[currentIndex];
        const otherContainer = pinnedContainers[currentIndex + 1];
        input.push({
          sortOrder: otherContainer.sortOrder,
          containerId
        }, {
          sortOrder: container.sortOrder,
          containerId: otherContainer.id
        });
        break;
      }
    default:
      throw new Error('Not supported');
  }
  services.updateSortOrder(input, (err, sortedTemplates) => {
    if (err) {
      dispatch({
        type: UPDATE_SORT_ORDER_FAILURE,
        groupId,
        containerId
      });
    } else {
      dispatch({
        type: UPDATE_SORT_ORDER_SUCCESS,
        groupId,
        containerId,
        sortedContainerIds: toDispatch,
        payload: sortedTemplates
      });
    }
  });
};
setGlobal('svs.components.marketplaceRetailAdminData.gameTemplates.actions', {
  fetchGameTemplates,
  FETCH_GAME_TEMPLATES_FAILURE,
  FETCH_GAME_TEMPLATES_INIT,
  FETCH_GAME_TEMPLATES_SUCCESS,
  UPDATE_GAME_TEMPLATE_INIT,
  UPDATE_GAME_TEMPLATE_FAILURE,
  UPDATE_GAME_TEMPLATE_SUCCESS,
  updateGameTemplate,
  FETCH_SINGLE_TEMPLATE_INIT,
  FETCH_SINGLE_TEMPLATE_SUCCESS,
  FETCH_SINGLE_TEMPLATE_FAILURE,
  fetchSingleTemplate,
  UPDATE_SORT_ORDER_INIT,
  UPDATE_SORT_ORDER_SUCCESS,
  UPDATE_SORT_ORDER_FAILURE,
  updateSortOrder
});

 })(window);