(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/shares/assets/javascripts/services/fetch-shares.js') >= 0) return;  svs.modules.push('/components/marketplace-data/shares/assets/javascripts/services/fetch-shares.js');
"use strict";


const {
  jupiter
} = svs.core;
function mapGroup(response) {
  const {
    offset,
    count,
    totalHits,
    currentShares
  } = response;
  return {
    offset,
    count,
    totalHits,
    currentShares
  };
}
const fetchShares = (offset, callback) => {
  let url = '/player/1/marketplace/activateddraws/currentshares';
  if (offset) {
    url += "?offset=".concat(offset);
  }
  jupiter.get(url, response => {
    callback(undefined, mapGroup(response));
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.shares.services.fetchShares', fetchShares);

 })(window);