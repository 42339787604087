(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/selectors/assets/javascripts/shares.js') >= 0) return;  svs.modules.push('/components/marketplace-data/selectors/assets/javascripts/shares.js');
"use strict";


const selectShares = state => {
  const {
    currentShares,
    offset,
    count,
    totalHits,
    loading,
    error,
    hasFetched
  } = state.Shares || {};
  return {
    loading,
    currentShares,
    offset,
    count,
    error,
    hasFetched,
    hasMoreData: currentShares.length < totalHits
  };
};
setGlobal('svs.components.marketplaceData.selectors.shares', {
  selectShares
});

 })(window);