(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/helper-games/assets/javascripts/show-few-shares-left-ribbon.js') >= 0) return;  svs.modules.push('/components/marketplace/helper-games/assets/javascripts/show-few-shares-left-ribbon.js');

'use strict';

const {
  GAME_STATUS_OPEN
} = svs.components.marketplaceData.gamesConstants;
const FEW_SHARES_LEFT_TEST_RETAILERS = [22561, 22998, 22152]; 

const showFewSharesLeftRibbon = (game, groupId) => {
  if (!game || !groupId) {
    return false;
  }
  const isTargeted = svs.components.targeting.isTargeted('TLM5_few_shares', 'B');
  const isInGroupIdList = FEW_SHARES_LEFT_TEST_RETAILERS.indexOf(groupId) !== -1;
  const isInFewSharesLeftTest = isTargeted && isInGroupIdList;
  let showFewSharesLeft = false;
  if (game.soldShares !== game.maxFractions && game.status === GAME_STATUS_OPEN) {
    if (!isInFewSharesLeftTest) {
      if (game.maxFractions < 5) {
        showFewSharesLeft = game.maxFractions - game.soldShares === 1;
      } else {
        showFewSharesLeft = game.soldShares / game.maxFractions >= 0.8;
      }
    } else {
      switch (game.maxFractions) {
        case 4:
          showFewSharesLeft = game.soldShares / game.maxFractions >= 2 / 4;
          break;
        case 5:
          showFewSharesLeft = game.soldShares / game.maxFractions >= 3 / 5;
          break;
        case 6:
          showFewSharesLeft = game.soldShares / game.maxFractions >= 4 / 6;
          break;
        case 7:
          showFewSharesLeft = game.soldShares / game.maxFractions >= 5 / 7;
          break;
        case 8:
          showFewSharesLeft = game.soldShares / game.maxFractions >= 6 / 8;
          break;
        case 9:
          showFewSharesLeft = game.soldShares / game.maxFractions >= 7 / 9;
          break;
        default:
          showFewSharesLeft = game.soldShares / game.maxFractions >= 0.8;
          break;
      }
    }
  }
  return showFewSharesLeft;
};

setGlobal('svs.components.marketplace.helpers.games.showFewSharesLeftRibbon', showFewSharesLeftRibbon);

 })(window);