(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/create-game-template/assets/javascripts/create-game-template-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/create-game-template/assets/javascripts/create-game-template-actions.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  dialogWrapper,
  errorCodeHandler
} = svs.components.marketplaceData;
const {
  gameTemplates
} = svs.components.marketplaceRetailAdminData;
const {
  services,
  helpers
} = svs.components.marketplaceRetailAdminData.createGameTemplate;
const getActionName = action => "CREATE_GAME_TEMPLATE/".concat(action);
const CHANGE_AUTO_OPEN = getActionName('CHANGE_AUTO_OPEN');
const CHANGE_BUY_STOP_DIFF = getActionName('CHANGE_BUY_STOP_DIFF');
const CHANGE_DESCRIPTION = getActionName('CHANGE_DESCRIPTION');
const CHANGE_FRACTION_PRICE = getActionName('CHANGE_FRACTION_PRICE');
const CHANGE_MAX_PLAYABLE_AMOUNT = getActionName('CHANGE_MAX_PLAYABLE_AMOUNT');
const CHANGE_COMMISSION_PERCENT = getActionName('CHANGE_COMMISSION_PERCENT');
const CALCULATION_UPDATED = getActionName('CALCULATION_UPDATED');
const CHANGE_MAX_FRACTIONS = getActionName('CHANGE_MAX_FRACTIONS');
const CHANGE_NAME = getActionName('CHANGE_NAME');
const CHANGE_TEMPLATE_NAME = getActionName('CHANGE_TEMPLATE_NAME');
const CHANGE_GAME_OWNER = getActionName('CHANGE_GAME_OWNER');
const CHANGE_PRODUCT = getActionName('CHANGE_PRODUCT');
const CLEAR_DATA = getActionName('CLEAR_DATA');
const CLEAR_ERROR = getActionName('CLEAR_ERROR');
const CREATE_GAME_TEMPLATE_FAILURE = getActionName('CREATE_GAME_TEMPLATE_FAILURE');
const CREATE_GAME_TEMPLATE_INIT = getActionName('CREATE_GAME_TEMPLATE_INIT');
const CREATE_GAME_TEMPLATE_SUCCESS = getActionName('CREATE_GAME_TEMPLATE_SUCCESS');
const FETCH_SINGLE_TEMPLATE_INIT = getActionName('FETCH_SINGLE_TEMPLATE_INIT');
const FETCH_SINGLE_TEMPLATE_FAILURE = getActionName('FETCH_SINGLE_TEMPLATE_FAILURE');
const FETCH_SINGLE_TEMPLATE_SUCCESS = getActionName('FETCH_SINGLE_TEMPLATE_SUCCESS');
const REMOVE_GAME_TEMPLATE_FAILURE = getActionName('REMOVE_GAME_TEMPLATE_FAILURE');
const REMOVE_GAME_TEMPLATE_INIT = getActionName('REMOVE_GAME_TEMPLATE_INIT');
const REMOVE_GAME_TEMPLATE_SUCCESS = getActionName('REMOVE_GAME_TEMPLATE_SUCCESS');
const UPDATE_GAME_TEMPLATE_INIT = getActionName('UPDATE_GAME_TEMPLATE_INIT');
const UPDATE_GAME_TEMPLATE_FAILURE = getActionName('UPDATE_GAME_TEMPLATE_FAILURE');
const UPDATE_GAME_TEMPLATE_SUCCESS = getActionName('UPDATE_GAME_TEMPLATE_SUCCESS');
const changeAutoOpen = isAutoOpened => ({
  type: CHANGE_AUTO_OPEN,
  isAutoOpened
});
const changeBuyStopDiff = buyStopDiff => ({
  type: CHANGE_BUY_STOP_DIFF,
  buyStopDiff
});
const changeDescription = function (description) {
  let maxLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 900;
  description = description.trim().slice(0, maxLength);
  return {
    type: CHANGE_DESCRIPTION,
    description
  };
};
const changeFractionPrice = fractionPrice => (dispatch, getState) => {
  dispatch({
    type: CHANGE_FRACTION_PRICE,
    fractionPrice
  });
  checkCalculation({
    fractionPrice
  })(dispatch, getState);
};
const changeMaxFractions = maxFractions => (dispatch, getState) => {
  dispatch({
    type: CHANGE_MAX_FRACTIONS,
    maxFractions
  });
  checkCalculation({
    maxFractions
  })(dispatch, getState);
};
const changeMaxPlayableAmount = maxPlayableAmount => (dispatch, getState) => {
  dispatch({
    type: CHANGE_MAX_PLAYABLE_AMOUNT,
    maxPlayableAmount
  });
  checkCalculation({
    maxPlayableAmount
  })(dispatch, getState);
};
const changeCommissionPercent = commissionPercent => (dispatch, getState) => {
  dispatch({
    type: CHANGE_COMMISSION_PERCENT,
    commissionPercent
  });
  checkCalculation({
    commissionPercent
  })(dispatch, getState);
};
const checkCalculation = change => (dispatch, getState) => {
  const key = Object.keys(change)[0];
  const value = change[key];
  if (!value && key !== 'commissionPercent') {
    return;
  }
  const {
    maxFractions,
    commissionPercent,
    maxPlayableAmount,
    fractionPrice
  } = getState().GameTemplates.Create;
  const input = ['fractionPrice', 'maxPlayableAmount'].indexOf(key) > -1 ? _objectSpread(_objectSpread({}, change), {}, {
    maxFractions,
    commissionPercent
  }) : _objectSpread({
    maxFractions,
    commissionPercent,
    maxPlayableAmount,
    fractionPrice
  }, change);
  const calculatedCommission = helpers.calculateCommission(input);
  if (calculatedCommission && JSON.stringify(calculatedCommission) !== '{}') {
    dispatch({
      type: CALCULATION_UPDATED,
      calculation: calculatedCommission
    });
  }
};
const changeName = name => ({
  type: CHANGE_NAME,
  name
});
const changeTemplateName = templateName => ({
  type: CHANGE_TEMPLATE_NAME,
  templateName
});
const changeGameOwner = function (ownerId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return _objectSpread({
    type: CHANGE_GAME_OWNER,
    ownerId
  }, options);
};
const changeProduct = productName => ({
  type: CHANGE_PRODUCT,
  productName
});
const clearData = () => ({
  type: CLEAR_DATA
});
const clearError = () => ({
  type: CLEAR_ERROR
});
const createGameTemplate = groupId => (dispatch, getState) => {
  dispatch({
    type: CREATE_GAME_TEMPLATE_INIT,
    groupId
  });
  const dataToSubmit = _objectSpread({
    groupId
  }, getState().GameTemplates.Create);
  services.createGameTemplate(groupId, dataToSubmit, (error, gameTemplate) => {
    if (error) {
      errorCodeHandler.getErrorMessage(error.code, err => {
        dispatch(dialogWrapper.actions.showDialog({
          title: err.message,
          type: 'error'
        }));
        dispatch({
          type: CREATE_GAME_TEMPLATE_FAILURE,
          error,
          groupId
        });
      }, {
        specificFormMessage: true,
        defaultMessage: 'Det gick inte att skapa spelmallen, försök om en stund igen.'
      });
    } else {
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Spelmallen har skapats',
        icon: 'checkmark'
      }));
      dispatch({
        type: CREATE_GAME_TEMPLATE_SUCCESS,
        gameTemplate,
        groupId,
        lastFetched: Date.now()
      });
    }
  });
};
const fetchSingleTemplate = (groupId, gameTemplateId) => dispatch => {
  dispatch({
    type: FETCH_SINGLE_TEMPLATE_INIT,
    groupId,
    gameTemplateId
  });
  gameTemplates.services.fetchSingleTemplate(groupId, gameTemplateId, (error, result) => {
    if (error) {
      dispatch({
        type: FETCH_SINGLE_TEMPLATE_FAILURE,
        groupId,
        gameTemplateId
      });
      const message = {
        404: 'Spelmallen kunde inte hittas'
      }[error.code] || error.message;
      return dispatch(dialogWrapper.actions.showDialog({
        title: message,
        type: 'error'
      }));
    }
    dispatch({
      type: FETCH_SINGLE_TEMPLATE_SUCCESS,
      gameTemplateId,
      template: result.template,
      groupId,
      user: result.user
    });
  });
};
const removeTemplate = (groupId, gameTemplateId) => dispatch => {
  dispatch({
    type: REMOVE_GAME_TEMPLATE_INIT,
    gameTemplateId,
    groupId
  });
  services.removeTemplate({
    gameTemplateId
  }, error => {
    if (error) {
      const errorMessage = 'Spelmallen kunde inte tas bort';
      dispatch(dialogWrapper.actions.showDialog({
        title: errorMessage,
        type: 'error'
      }));
      return dispatch({
        type: REMOVE_GAME_TEMPLATE_FAILURE,
        errorMessage,
        gameTemplateId,
        groupId
      });
    }
    dispatch(dialogWrapper.actions.showDialog({
      title: 'Spelmallen har tagits bort',
      icon: 'checkmark'
    }));
    dispatch({
      type: REMOVE_GAME_TEMPLATE_SUCCESS,
      gameTemplateId,
      groupId
    });
  });
};
const updateGameTemplate = (groupId, gameTemplateId) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_GAME_TEMPLATE_INIT,
    gameTemplateId,
    groupId
  });
  const templateData = _objectSpread(_objectSpread({}, getState().GameTemplates.Create), {}, {
    id: gameTemplateId
  });
  gameTemplates.services.updateTemplate(gameTemplateId, {
    ownerId: templateData.ownerId,
    buyStopDiff: templateData.buyStopDiff,
    description: templateData.description,
    isAutoOpened: templateData.isAutoOpened
  }, (error, gameTemplate) => {
    if (error) {
      dispatch({
        type: UPDATE_GAME_TEMPLATE_FAILURE,
        error,
        gameTemplateId,
        groupId
      });
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Det gick tyvärr inte att spara spelmallen. Vänligen kontrollera och åtgärda eventuella felaktigheter.',
        type: 'error'
      }));
      return;
    }
    dispatch(dialogWrapper.actions.showDialog({
      title: 'Spelmallen har updaterats',
      icon: 'checkmark'
    }));
    return dispatch({
      type: UPDATE_GAME_TEMPLATE_SUCCESS,
      gameTemplateId,
      groupId,
      gameTemplate
    });
  });
};
setGlobal('svs.components.marketplaceRetailAdminData.createGameTemplate.actions', {
  CHANGE_AUTO_OPEN,
  changeAutoOpen,
  CHANGE_BUY_STOP_DIFF,
  changeBuyStopDiff,
  CHANGE_DESCRIPTION,
  changeDescription,
  CHANGE_FRACTION_PRICE,
  changeFractionPrice,
  CHANGE_MAX_FRACTIONS,
  changeMaxFractions,
  CHANGE_MAX_PLAYABLE_AMOUNT,
  changeMaxPlayableAmount,
  CHANGE_COMMISSION_PERCENT,
  changeCommissionPercent,
  CALCULATION_UPDATED,
  checkCalculation,
  CHANGE_NAME,
  changeName,
  CHANGE_TEMPLATE_NAME,
  changeTemplateName,
  CHANGE_GAME_OWNER,
  changeGameOwner,
  CHANGE_PRODUCT,
  changeProduct,
  CLEAR_DATA,
  clearData,
  CLEAR_ERROR,
  clearError,
  FETCH_SINGLE_TEMPLATE_INIT,
  FETCH_SINGLE_TEMPLATE_FAILURE,
  FETCH_SINGLE_TEMPLATE_SUCCESS,
  fetchSingleTemplate,
  CREATE_GAME_TEMPLATE_FAILURE,
  CREATE_GAME_TEMPLATE_INIT,
  CREATE_GAME_TEMPLATE_SUCCESS,
  createGameTemplate,
  REMOVE_GAME_TEMPLATE_FAILURE,
  REMOVE_GAME_TEMPLATE_INIT,
  REMOVE_GAME_TEMPLATE_SUCCESS,
  removeTemplate,
  UPDATE_GAME_TEMPLATE_INIT,
  UPDATE_GAME_TEMPLATE_FAILURE,
  UPDATE_GAME_TEMPLATE_SUCCESS,
  updateGameTemplate
});

 })(window);