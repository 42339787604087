(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/helpers/assets/javascripts/get-image-url.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/helpers/assets/javascripts/get-image-url.js');
'use strict';

const {
  svsconfig
} = svs.core.config.data;
const getDefaultCoverImageUrl = () => "".concat(svsconfig.cdn, "/images/marketplace-retail-admin/edit-retailer/retail-coverimage-default.jpg");
const getCoverImageUrl = coverImage => coverImage && coverImage.path ? "".concat(svsconfig.cdn2).concat(coverImage.path, "/1280.jpg") : getDefaultCoverImageUrl();
setGlobal('svs.components.marketplaceRetailAdmin.helpers.getCoverImageUrl', {
  getCoverImageUrl,
  getDefaultCoverImageUrl
});

 })(window);