(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/image-v2/assets/javascripts/multi-image.js') >= 0) return;  svs.modules.push('/components/content/image-v2/assets/javascripts/multi-image.js');
'use strict';

const {
  useEffect,
  useState
} = React;
const {
  clsvs,
  svsuid
} = svs.utils;
const {
  getNoscriptUrl,
  getUrlWithCorrectHost,
  getSourceElementsData,
  setStyleElement
} = Handlebars.helpers;
const config = {
  options: svs.core.config.data.svsconfig
};
const logger = new svs.core.log.Logger('image-v2:React_MultiImage');
const MultiImage = _ref => {
  let {
    altText,
    title,
    images,
    cssSelectors,
    noscriptUrl,
    loadingLazy,
    fitWrapper,
    onLoadCallback,
    fallbackImage
  } = _ref;
  const [hasError, setHasError] = useState(false);
  const uniqueSelector = "image-".concat(svsuid());
  const $noscriptUrl = getNoscriptUrl(undefined, images, noscriptUrl, true);
  const fallbackImageUrl = getUrlWithCorrectHost(fallbackImage, config);
  const [currentAlt, setCurrentAlt] = useState(altText);
  const [currentTitle, setCurrentTitle] = useState(title);
  const itemType = 'http://schema.org/ImageObject';
  const imageClassNames = clsvs('image-wrapper', uniqueSelector, fitWrapper && 'background-image-wrapper image-wrapper-fit', cssSelectors);
  const handleError = ev => {
    const {
      currentTarget
    } = ev;
    currentTarget.onerror = null;
    if (fallbackImage) {
      setHasError(true);
    } else {
      logger.warn("Failed to load image: ".concat(currentTarget.currentSrc));
    }
  };
  const handleImageLoad = ev => {
    const {
      currentTarget
    } = ev;
    const currentSrc = currentTarget.currentSrc;
    const imageArray = Object.values(images);
    const matchingImage = imageArray.find(img => {
      const urlToMatch = img.url.split('/').slice(-3).join('/');
      return currentSrc.includes(urlToMatch);
    });
    if (matchingImage) {
      setCurrentAlt(matchingImage.altText);
      setCurrentTitle(matchingImage.title);
    }
    if (onLoadCallback) {
      onLoadCallback(ev);
    }
  };
  useEffect(() => {
    setStyleElement(uniqueSelector, images);
  }, [images, uniqueSelector]);
  const Img = () => {
    const ImgSrc = hasError ? fallbackImageUrl : $noscriptUrl;
    return React.createElement("img", {
      alt: currentAlt,
      className: "js-image image",
      "data-fallback-image": hasError,
      decoding: "sync",
      itemProp: "image",
      loading: loadingLazy ? 'lazy' : 'eager',
      onLoad: handleImageLoad,
      src: ImgSrc,
      title: currentTitle
    });
  };
  const imagesArray = images ? Object.values(images) : [];
  return React.createElement("div", {
    className: imageClassNames,
    "data-is-react": true,
    itemScope: true,
    itemType: itemType
  }, React.createElement("meta", {
    content: hasError ? fallbackImageUrl : $noscriptUrl,
    itemProp: "url"
  }), hasError ? React.createElement(Img, null) : React.createElement("picture", null, imagesArray.map(image => {
    const sourceElements = getSourceElementsData(image);
    return sourceElements.map(source => React.createElement("source", {
      key: source.id,
      media: source.media,
      sizes: source.sizes,
      srcSet: source.srcSet
    }));
  }), React.createElement("img", {
    alt: currentAlt,
    className: "js-image image",
    decoding: "sync",
    itemProp: "image",
    loading: loadingLazy ? 'lazy' : 'eager',
    onError: handleError,
    onLoad: handleImageLoad,
    src: !loadingLazy ? $noscriptUrl : null,
    title: currentTitle
  })));
};
svs.content.image_v2.MultiImage = MultiImage;

 })(window);